import Vue from 'vue'
import VueRouter from 'vue-router'
import Chestionar from '../pages/Chestionar.vue'
import Oprit from '../pages/Oprit.vue'
import Analiza from '../pages/Analiza.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Chestionar',
    component: Chestionar
  },
  // {
  //   path: '/',
  //   name: 'Oprit',
  //   component: Oprit
  // },
  // {
  //   path: '/',
  //   name: 'Analiza',
  //   component: Analiza
  // },
  {
    path: '/preview',
    name: 'Preview',
    component: Chestionar
  },
]

const router = new VueRouter({
  routes
})

export default router
